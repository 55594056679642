@import url("https://fonts.googleapis.com/css2?family=Ruda:wght@400;500;600;700;800;900&display=swap");


/* /// SECTIONS */
.sim-page {
  height: 92vh;
  background-color: #e5e5e5;
  color: var(--mainBlue);
  padding: 10px;
  min-height: 500px;
}

.sim-container-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 55%;
  margin-right: 10px;
}

.sim-results-box {
  height: 100%;
  width: calc(45% - 10px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* /// LOADER */
#loader {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* /// SIM-NAV */

.title {
  background-color: var(--mainBlue);
  /* background-color: white; */
  border-radius: 2px;
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

#sim-title {
  height: 40px;
  margin-bottom: 10px;
}

#sim-title h1,
#results-top-box h1,
#results-emissions h1,
#results-button h1 {
  font-family: var(--mainPolice);
  font-size: 18px;
  font-weight: normal;
  color: #ffffff;
  /* color: var(--mainBlue); */
  padding-bottom: 0;
  margin-bottom: 0;
  line-height: 20px;
  margin-left: 16px;
}

#sim-nav-box {
  align-items: center;
  height: 120px;
  padding: 10px;
  background-color: white;
  width: 100%;
  margin-bottom: 10px;
}

#sim-nav-box h2 {
  /* font-family: "Circular Std"; */
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
}

#sim-nav-box > div {
  width: 100%;
  justify-content: space-between;
  margin-top: 0.3em;
}

#sim-nav-fr {
  width: 72%;
  justify-content: space-between;
}

#sim-nav-world {
  width: 13%;
}

.sim-nav {
  width: 100%;
}

.sim-nav-categories {
  width: 100%;
  border-top: solid rgba(0, 0, 0, 0.2) 1px;
  justify-content: space-between;
  padding-top: 5px;
}

.sim-nav-category {
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  position: relative;
  display: inline-block;
  height: 45px;
}

.sim-nav-category .sim-nav-category-background {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border-radius: 5px;
  opacity: 0;
  transition: 1s;
}

.sim-nav-category:hover .sim-nav-category-background {
  opacity: 1;
}

.sim-nav-category .sim-nav-category-icon {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}

.sim-nav-category .sim-nav-category-icon-helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.sim-nav-category img {
  width: 90%;
  max-width: 30px;
  display: inline-block;
  vertical-align: middle;
}

#sim-nav-fr .sim-nav-category {
  width: 16%;
}

#sim-nav-world .sim-nav-category {
  width: 95%;
}

#options {
  width: 12%;
  margin-top: 31px;
  border: none;
}

/* /// MAIN-BOX */
.sim-main-box {
  background-color: white;
  height: auto;
  scroll-behavior: smooth;
  overflow-y: auto;
  padding: 10px;
  height: calc(100% - 180px);
  width: 100%;
}

/* /// TITRES CATEGORIES */

.sim-categorie {
  padding: 1.5% 1em;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2%;
  height: auto;
  width: 100%;
  text-align: center;
}

.sim-categorie > div {
  width: 55%;
  justify-content: space-between;
  align-items: center;
}

.sim-jauge {
  width: 100%;
  height: 20px;
  margin: 5px 0;
}

.sim-categorie-emissions {
  font-size: 15px;
  line-height: 19px;
}

.sim-categorie-markers {
  /* justify-content: space-evenly; */
  align-items: baseline;
  width: 100%;
}

.sim-categorie-markers > div {
  align-items: center;
  width: 24%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.MuiTooltip-tooltipArrow {
  background-color: blue;
}

.sim-categorie-markers > div > div {
  width: 100%;
}

/* 
.sim-categorie-markers > div > div:last-of-type {
  width: 70%;
} */

.sim-categorie-markers p {
  font-size: 11px;
  text-align: center;
}

.sim-emissions-2030 {
  color: #0b8c85;
}

.sim-emissions-2020 {
  color: #ff6868;
}

.sim-emissions-2020 span {
  font-size: 9px;
}

.sim-emissions-2030 span {
  font-size: 9px;
}

.sim-categorie-name {
  font-weight: bold;
  font-size: 23px;
  line-height: 32px;
  text-align: left;
  width: 45%;
}

/* /// OPTIONS */

#optionsContainer {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  text-align: center;
  z-index: 9999;
}

#optionsContainer .optionsContainerBackground {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background: rgba(0, 0, 0, 0.5);
}

#optionsContainer .optionsContainerClose {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 2rem;
  cursor: pointer;
}

#scrollOptions {
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  color: #163e59;
}

#scrollOptions > div {
  justify-content: center;
}

.sim-options {
  justify-content: space-between;
  align-items: center;
  padding: 1em 1em 0;
}

.sim-option-box {
  width: 90%;
  margin-bottom: 30px;
}

.sim-option-box p {
  margin-bottom: 0.5em;
  font-size: 0.8em;
}

.sim-option-box label {
  font-size: 1em;
  margin-right: 0.5em;
}

.sim-option-form {
  justify-content: flex-start;
}

.sticky {
  margin-right: -0.3em;
  z-index: 2;
  position: sticky;
  position: -webkit-sticky;
  padding-bottom: 20px;
  margin-bottom: 10px;
  width: 100%;
  max-width: 800px;
  margin: auto;
  text-align: left;
  margin-top: 150px;
}

.sticky .sim-categorie {
  background: #b0e0e6;
}

.sim-param-box {
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin-bottom: 2%;
}

.sim-param-radio {
  margin-right: 1em;
}

input[type="radio"] {
  margin-right: 1em;
}

.bigger {
  height: 2.2em;
}

/* /// JAUGES */
.jauge-ext {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.tooltip {
  /* font-weight: 900 !important;
  font-size: 15px !important;
  line-height: 18px !important; */
  position: absolute;
  left: 50%;

  text-align: left;
  white-space: nowrap;
  transform: translate(-50%, -150%);
  transition: all 0.15s ease-in-out;
}

.tooltip::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  width: 0;
  height: 0;
  border: 0.5em solid transparent;
  border-top-color: #000;
  transform: translate(-50%, 0);
}

.marker1,
.marker2 {
  z-index: 3;
}
/* /////////////////////// */
/* /// RESULTS */
/* /////////////////////// */

/* SECTIONS */

#results-top-box {
  width: 100%;
  height: 40px;
}
#results-climat-box {
  width: 100%;
  height: 31%;
  background-color: white;
  padding: 10px;
  margin-bottom: 10px;
}
#results-energie-box {
  width: 100%;
  height: 31%;
  background-color: white;
  padding: 10px;
  margin-bottom: 10px;
}
#results-air-box {
  width: 100%;
  height: 18%;
  background-color: white;
  padding: 10px;
  margin-bottom: 10px;
}
#results-button {
  width: 100%;
  height: calc(20% - 80px);
  background: var(--mainBlue);
  border-radius: 2px;
  display: flex;
  padding-right: 16px;
  justify-content: flex-end;
  align-items: center;
}

#results-button:hover {
  background-color: #012340;
}

.results-content-box {
  width: 100%;
  height: calc(100% - 26px);
  display: flex;
  justify-content: space-between;
}

/* GRAPH BOX */

.graph-box {
  width: 35%;
  height: 100%;
}
.graph-box > p {color: #797979; font-size:13px;text-align: right;margin-bottom: 2px;}

.graph-compo {
  width: calc(100%);
  height: calc(100% - 15px);
}

/* INDICATOR BOX */

.indicators-box {
  width: calc(65% - 24px);
  height: 100%;
  display: flex;
  flex-direction: column;
}

.indicators-main-box {
  width: 100%;
  height: 50%;
}
.indicators-secondary-box {
  width: 100%;
  height: 50%;
  display: flex;
}
.indicator {
  margin-right: 10px;
}
.indicator:last-child {
  margin-right: 0;
}

.results-title {color:var(--mainBlue);font-size: 10px;}

#results-climat-box .indicators-secondary-box .results-title {height: 32px;margin-top: -4px;}

.results-figure {
  font-family: var(--mainPolice);
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  width: 100%;
  height: 45px;
}

.indicator-tooltip {font-size: 14px;margin-left: 10px;opacity: 0.7;}

.indicators-main-box .results-figure {font-size: 25px;}

/* /// RESULTS OLD*/

/* /// PROJECTION MONDIALE */
/* #results-top-box {
  background-color: white;
  height: 180px;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
} */

#results-impacts-box {
  width: 100%;
  align-items: baseline;
  margin-top: 0.5em;
}

.n1 {
  grid-area: title1;
  text-align: center;
  align-self: center;
  margin: 0;
}

.n4 {
  grid-area: title2;
  text-align: center;
  align-self: center;
  margin: 0;
}

.n7 {
  grid-area: title3;
  text-align: center;
  align-self: center;
  margin: 0;
}

.n2 {
  grid-area: tag1;
  justify-self: center;
  margin: 0;
}

.n5 {
  grid-area: tag2;
  justify-self: center;
  margin: 0;
}

.n8 {
  grid-area: tag3;
  justify-self: center;
  margin: 0;
}

.n3 {
  grid-area: legend1;
  text-align: center;
  margin: 0;
}

.n6 {
  grid-area: legend2;
  text-align: center;
  margin: 0;
}

.n9 {
  grid-area: legend3;
  text-align: center;
  margin: 0;
}

#results-impacts-box {
  display: grid;
  width: 100%;
  margin: 0;
  grid: auto auto auto / 1fr 1fr 1fr;
  gap: 2px 10px;
  grid-template-areas:
    "title1 title2 title3"
    "tag1 tag2 tag3"
    "legend1 legend2 legend3";
  margin-top: 0.5em;
}

/* /// PROJECTION FRANCAISE */

#results-bottom-box {
  background-color: white;
  height: calc(100% - 190px);
  width: 100%;
  padding: 10px;
  justify-content: space-between;
}

#results-emissions {
  width: 100%;
  align-items: center;
}

#results-emissions-charts-container {
  height: 55%;
  width: 100%;
  display: grid;
  grid: 85% 13% / 46% 46%;
  gap: 5px 20px;
  grid-template-areas:
    "g1 g3"
    "g2 g4";
}

.g1 {
  grid-area: g1;
  width: 100%;
  height: 100%;
}

.g3 {
  grid-area: g3;
  width: 100%;
  height: 100%;
}

.g2 {
  grid-area: g2;
  text-align: center;
}

.g4 {
  grid-area: g4;
  text-align: center;
}

#results-emissions-charts-container p {
  text-align: center;
  font-size: 1.1em;
}

.chart {
  width: 100%;
  height: 100%;
}

#area-tooltip {
  z-index:100;
  padding: 10px;
  width: 350px;
  align-items: flex-start;
}

#area-tooltip .legend-point {
  margin-left: 20px;
  min-width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}

#area-tooltip > div {
  align-items: center;
}

.chart-tooltip {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid lightgrey;
  box-shadow: 3px 3px 3px #163e59;
  font-size: 0.8em;
}

.chart-tooltip h4 {
  font-size: 1.1em;
  align-self: center;
  color:var(--mainBlue)
}

.chart-tooltip p {
  font-size: 0.9em;
  align-self: center;
  color:var(--mainBlue)
}

/* /// COMPOSANT INDICATEUR AVEC TITRE BOX ET LEGENDE */
#results-impacts-box2 {
  display: grid;
  width: 100%;
  margin: 0;
  grid: auto auto auto / 1fr 1.2fr 1fr;
  gap: 2px;
  grid-template-areas:
    "title4 title5 title6"
    "tag4 tag5 tag6"
    "legend4 legend5 legend6";
  margin-top: 0.5em;
}

.b1 {
  grid-area: title4;
  text-align: center;
  align-self: center;
  margin: 0;
}

.b4 {
  grid-area: title5;
  text-align: center;
  align-self: center;
  margin: 0;
}

.b7 {
  grid-area: title6;
  text-align: center;
  align-self: center;
  margin: 0;
}

.b2 {
  grid-area: tag4;
  justify-self: center;
  margin: 0;
}

.b5 {
  grid-area: tag5;
  justify-self: center;
  text-align: center;
  margin: 0;
}

.b8 {
  grid-area: tag6;
  justify-self: center;
  margin: 0;
}

.b3 {
  grid-area: legend4;
  text-align: center;
  margin: 0;
}

.b6 {
  grid-area: legend5;
  text-align: center;
  margin: 0;
}

.b9 {
  grid-area: legend6;
  text-align: center;
  margin: 0;
}

.results-legend {
  font-size: 0.7em;
  padding: 0 20px;
  text-align: center;
  justify-self: center;
}

#results-impacts-box2 .results-figure {
  font-size: 1.5rem;
  /* padding: 0.1em 1.2em; */
}

.figure-unit {
  font-size: 0.7em;
  font-weight: lighter;
  margin-top: -0.2em;
}

/* #results-button {
  height: auto;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: white;
} */

.sim-results-box button {
  padding: 0.5em 2em;
  font-size: 1.4em;
}

@media screen and (max-width: 1110px) {
  body {
    font-size: 3px;
  }

  .sim-container-box {
    width: 50%;
  }

  .sim-results-box {
    width: calc(50% - 10px);
  }
}

@media screen and (min-height: 1100px) {
  #results-emissions-charts-container {
    grid: 40% 6% 40% 6% / 60%;
    gap: 5%;
    justify-content: center;
    grid-template-areas:
      "g1"
      "g2"
      "g3"
      "g4";
  }
}

@media screen and (max-height: 600px) {
  .sim-categorie-emissions {font-size: 11px;}

  #results-top-box {height: 150px; }

  #results-bottom-box {height: calc(100% - 150px);}

  .results-title {font-size: 9px;}

  .results-figure {
    font-size: 1.2em;
    width: 5em;
    height: 2.5rem;
    line-height: 2.5rem;
  }

  .b5 {
    line-height: normal;
  }

  #results-impacts-box2 .results-figure {
    font-size: 1.2rem;
  }

  .results-legend {
    font-size: 0.6em;
  }

  .b9 {
    font-size: 0.5em;
  }

  #results-emissions-charts-container {
    height: 45%;
    grid-gap: 2px 15px;
  }

  #results-emissions-charts-container p {
    font-size: 1em;
  }

  .sim-results-box button {
    padding: 0.4em 2em;
    font-size: 1.2em;
  }
}
