#Checkbox {
  width: 16px;
  height: 16px;
  border: 2px solid #000000;
  border-radius: 1px;
  position: relative;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  background-color: #e5eaec;
}

#Checkbox .active {
  top: 50%;
  left: 50%;
  width: 50%;
  height: 50%;
  border-radius: 1px;
  transform: translate(50%, 50%);
  background-color: #00adb9;
}
